import { Button, Heading, LogoIcon, Text } from '@library/uikit'
import Page from 'components/Layout/Page'
import { useTranslation } from '@library/localization'
import Link from 'next/link'
import styled from 'styled-components'

const StyledCenter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: center;
`

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Page>
      <StyledCenter>
        <LogoIcon width="120px" mb="8px" />
        <Heading scale="xxl">404</Heading>
        <Text mb="16px">{t('Oops, page not found.')}</Text>
        <Link href="/" passHref>
          <Button as="a" scale="sm">
            {t('Back Home')}
          </Button>
        </Link>
      </StyledCenter>
    </Page>
  )
}

export default NotFound
