import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  const theme = useTheme();
  const primaryColor = theme.isDark ? "#3C3742" : "#e9eaeb";
  const secondaryColor = theme.isDark ? "#666171" : "#bdc2c4";
  return (
    <Svg viewBox="0 0 1024 1024" {...props}>
      <path
        d="M954.432 199.12a376.096 376.096 0 0 0-110.992-82.832 372.208 372.208 0 0 0-65.6-24.768 603.6 603.6 0 0 0-124-24.848 613.856 613.856 0 0 0-69.104-2.544 609.216 609.216 0 0 0-141.552 13.744 602.32 602.32 0 0 0-149.168 53.84 374.976 374.976 0 0 0-108.688 75.792 375.808 375.808 0 0 0-42.656 60.848 372.352 372.352 0 0 0-41.44 113.936 233.12 233.12 0 0 1-21.728-28.768l-0.064 0.096c-4.016-6.336-7.872-12.8-11.52-19.392C52.128 305.68 39.68 274.88 32 242.4V455.44c12.48 29.696 29.584 57.392 49.376 82.496a376.432 376.432 0 0 0 114.048 96.72A373.392 373.392 0 0 0 373.76 671.2c22.96-0.96 45.376-4 67.088-8.896 11.792-3.44 23.184-7.792 34.112-12.96a232.416 232.416 0 0 0 119.36-132.112 87.808 87.808 0 0 0 6.208-32.576 87.856 87.856 0 0 0-11.024-42.784 54.768 54.768 0 0 0-31.632-26.944l-0.336-0.176a33.6 33.6 0 0 0-14.592-3.312c-6.448 0-12.448 1.808-17.568 4.944-6.56 3.616-11.792 9.344-14.768 16.272a34.272 34.272 0 0 1 22.304-9.968c4.736 0.288 9.2 1.552 13.216 3.584 1.472 0.624 2.832 1.424 4.096 2.352 1.568 1.504 2.896 3.248 3.936 5.184l0.256 0.496 0.432 0.864c1.968 4.144 3.408 8.576 4.272 13.232a87.856 87.856 0 0 1-3.248 27.824l-0.032 0.112a89.024 89.024 0 0 1-4 11.104c-1.6 3.68-3.44 7.2-5.504 10.608a144.016 144.016 0 0 1-21.68 21.264c-9.584 7.584-20.16 13.952-31.52 18.912-6.56 2.368-13.28 4.464-20.112 6.24a142.288 142.288 0 0 1-39.504 5.52c-3.408 0-6.8-0.128-10.16-0.368a142.384 142.384 0 0 1-67.648-23.952 143.728 143.728 0 0 1-53.312-66.656 142.832 142.832 0 0 1-8.4-71.712 231.136 231.136 0 0 1 65.728-129.024 232.24 232.24 0 0 1 64.224-45.12l0.784-0.352a599.376 599.376 0 0 1 77.584-28.176 606.336 606.336 0 0 1 167.776-23.52c12.64 0 25.2 0.4 37.664 1.168l0.128 0.096-0.048-0.096a614.592 614.592 0 0 1 72.8 8.896 602.56 602.56 0 0 1 211.104 75.6 376.512 376.512 0 0 0-37.328-47.648"
        fill={primaryColor}
      />
      <path
        d="M837.184 135.072a372.08 372.08 0 0 0-32.208-13.024A601.504 601.504 0 0 0 695.568 92.8a610.608 610.608 0 0 0-101.632-8.48c-41.12 0-81.28 4.096-120.096 11.904-20.528 4.112-40.688 9.28-60.416 15.408a374.464 374.464 0 0 0-124.32 63.36 375.552 375.552 0 0 0-105.648 128 372.224 372.224 0 0 0-37.44 124.88 377.184 377.184 0 0 1-62.592-64.688C62.176 335.184 44.464 304.112 32 270.752v54.128a584.288 584.288 0 0 0 28.512 76.992 605.712 605.712 0 0 0 90.08 142.8l-0.16-0.736a232.64 232.64 0 0 0 79.488 65.536 230.032 230.032 0 0 0 64.448 21.216 375.936 375.936 0 0 0 66.416 5.856c16.608 0 32.96-1.088 49.008-3.168a231.2 231.2 0 0 0 23.712-3.84l2.784-0.592a229.904 229.904 0 0 0 101.392-52 142.752 142.752 0 0 0 49.728-113.376v-0.08a54.512 54.512 0 0 0-12.368-34.656 54.336 54.336 0 0 0-13.04-11.488 33.68 33.68 0 0 0-4.112-2.4l-0.336-0.192a33.6 33.6 0 0 0-14.592-3.312c-6.448 0-12.448 1.808-17.568 4.944-6.56 3.616-11.792 9.344-14.768 16.272a33.808 33.808 0 0 1 4.912-4.096 33.536 33.536 0 0 1 17.376-5.872c4.752 0.288 9.216 1.552 13.232 3.584 1.472 0.624 2.832 1.424 4.096 2.352 1.568 1.504 2.896 3.248 3.936 5.184l0.256 0.496 0.432 0.864c1.968 4.144 3.408 8.576 4.272 13.232a89.44 89.44 0 0 1-3.248 27.824l-0.032 0.112a87.472 87.472 0 0 1-9.504 21.712 144.096 144.096 0 0 1-21.68 21.264c-9.584 7.584-20.16 13.952-31.52 18.912-6.56 2.368-13.28 4.464-20.112 6.24a142.464 142.464 0 0 1-39.504 5.52 142.72 142.72 0 0 1-100.896-41.584 143.616 143.616 0 0 1-31.376-46.704 142.304 142.304 0 0 1-10.272-41.392 235.248 235.248 0 0 1-0.624-15.392l-0.016-1.872c0-24.752 3.888-48.592 11.072-70.96a232.208 232.208 0 0 1 79.424-104.768 375.136 375.136 0 0 1 87.792-46.048 602.96 602.96 0 0 1 25.504-8.24l2.688-0.784a602.912 602.912 0 0 1 79.68-17.6 611.36 611.36 0 0 1 99.472-6.784 627.68 627.68 0 0 1 42.384 0.912 602.432 602.432 0 0 1 220.112 54.784 375.52 375.52 0 0 0-101.28-68.48"
        fill={secondaryColor}
      />
      <path
        d="M930.4 449.536a368.72 368.72 0 0 0-2.72-7.744 230.32 230.32 0 0 0-15.104-34.72 232.768 232.768 0 0 0-67.36-77.472 373.232 373.232 0 0 0-222.688-73.312 379.472 379.472 0 0 0-86.368 10 142.032 142.032 0 0 0-45.12 17.664 143.44 143.44 0 0 0-44.32 41.92 88.704 88.704 0 0 0-8.448 13.984 88.08 88.08 0 0 0-8.736 38.4 88.128 88.128 0 0 0 14.368 48.352 88.96 88.96 0 0 0 42.416 28.096 54.288 54.288 0 0 0 17.28 2.8c7.952 0 15.52-1.728 22.336-4.784a54.656 54.656 0 0 1-53.28-9.6 88.96 88.96 0 0 1-25.92-32.336 54.368 54.368 0 0 1-5.76-29.44c0.944-16.432 6.384-31.68 15.088-44.496a88.96 88.96 0 0 1 31.584-28.4 230.336 230.336 0 0 1 111.792-29.84l2.032-0.016c12.64 0 25.056 1.024 37.152 2.976 33.424 4.288 65.44 12.96 95.52 25.44a232.688 232.688 0 0 1 117.104 103.76 230.624 230.624 0 0 1 27.952 127.008 231.248 231.248 0 0 1-6.384 40.768 372.048 372.048 0 0 1-23.52 72.64 375.76 375.76 0 0 1-86.352 121.824 374.16 374.16 0 0 1-165.792 90.608c-8.912 2.688-17.952 5.056-27.12 7.104l-0.752 0.176a609.216 609.216 0 0 1-130.976 14.176 607.36 607.36 0 0 1-175.712-25.84 602.32 602.32 0 0 1-112-42.176A599.456 599.456 0 0 1 32 750.128V804.624a594.544 594.544 0 0 0 126.4 72.512 603.648 603.648 0 0 0 263.408 60.016c14.304 0 28.48-0.496 42.56-1.472l3.392 0.016a608.352 608.352 0 0 0 116.8-11.248 601.952 601.952 0 0 0 89.376-24.704 373.76 373.76 0 0 0 137.968-71.056 376.528 376.528 0 0 0 94.576-115.184A372.56 372.56 0 0 0 944 549.888c0-34.768-4.752-68.4-13.6-100.352"
        fill={primaryColor}
      />
      <path
        d="M851.232 410.768a232.688 232.688 0 0 0-117.104-103.744 372.304 372.304 0 0 0-95.504-25.44 233.248 233.248 0 0 0-37.152-2.992l-2.032 0.016a231.952 231.952 0 0 0-68.64 10.976c-15.12 4.832-29.568 11.184-43.152 18.864a88.528 88.528 0 0 0-31.584 28.4 88.016 88.016 0 0 0-15.104 44.496 55.472 55.472 0 0 0-0.128 7.44 54.56 54.56 0 0 0 5.888 21.984c0.752 1.6 1.536 3.184 2.368 4.72a88.96 88.96 0 0 0 23.568 27.632 54.816 54.816 0 0 0 35.088 12.704c6.368 0 12.48-1.088 18.192-3.104l0.656-0.288a54.448 54.448 0 0 1-24.432-12.16 55.056 55.056 0 0 1-16.336-24.672 54.528 54.528 0 0 1-2.64-16.736c0-15.792 6.688-30.016 17.392-40 0.4-0.448 0.832-0.896 1.264-1.328a88.864 88.864 0 0 1 13.92-10.64l0.272-0.192a88.48 88.48 0 0 1 19.888-9.024 142.416 142.416 0 0 1 34.352-5.2 234.496 234.496 0 0 1 22.528 1.6l2.064 0.24a218.672 218.672 0 0 1 12.416 1.968h0.048l1.552 0.288 0.592 0.16v-0.048c24.72 4.8 58.8 18.368 69.28 25.552 10.496 7.168 19.312 14.96 27.92 23.392l0.112 0.112a231.888 231.888 0 0 1 47.184 73.184c6.784 20.928 10.688 43.152 11.216 66.224a230.688 230.688 0 0 1-19.36 87.6 232.128 232.128 0 0 1-49.216 71.632 377.12 377.12 0 0 1-66.96 50.128 373.888 373.888 0 0 1-42.528 21.456 370.08 370.08 0 0 1-14.88 5.984 600.64 600.64 0 0 1-60.288 18.512 608.784 608.784 0 0 1-169.456 17.856 613.216 613.216 0 0 1-57.968-4.752l-0.336-0.048c-3.728-0.48-7.44-1.024-11.136-1.584l-2.272-0.352a605.2 605.2 0 0 1-30.304-5.44l-1.024-0.912 0.272 0.736a588.08 588.08 0 0 1-87.136-25.056c-11.616-4.32-23.04-8.976-34.32-13.968A581.696 581.696 0 0 1 32 699.968v50.16a599.408 599.408 0 0 0 118.592 66.928 602.336 602.336 0 0 0 112 42.176 607.328 607.328 0 0 0 175.728 25.856 609.216 609.216 0 0 0 130.96-14.192l0.752-0.176a372.352 372.352 0 0 0 27.12-7.104 374.16 374.16 0 0 0 165.792-90.608 375.76 375.76 0 0 0 86.352-121.824 372.08 372.08 0 0 0 23.52-72.64 231.248 231.248 0 0 0 6.928-56.368c0-40.384-10.336-78.336-28.512-111.408"
        fill={secondaryColor}
      />
    </Svg>
  );
};

export default Icon;
